import React from "react";
import classes from "./privacy.module.css";
import iDoctorsLogo2 from "../../assets/iDoctors logo with text-01.png";

const Privacy = () => {
  return (
    <div className={classes.privacyContainer}>
      <div className={classes.imgContainer}>
        <img src={iDoctorsLogo2} alt="" />
        <h1>Privacy Policy</h1>
      </div>
      <div className={classes.div}>
        <p>
          This Privacy Policy describes the information ARQAAM-IT,
          ("ARQAAM," "we," "us," or "our") collects when you sign up for, use,
          access or otherwise interact with any ARQAAM applications, products, services,
          or websites (collectively, “Services”), how we use and share that information,
          and the choices we offer. This policy applies to all information we collect through our Services,
          even if you have not signed up for a ARQAAM account or downloaded one of our applications.
          This policy does not, however, describe the practices of third parties that may collect information
          from you when you interact with them. We encourage you to ask them about their privacy practices
          before providing any information to them.
        </p>
        <p>
          We may change this policy from time to time.
          If we make changes, we will notify you by posting the updated policy and revising
          the "Last Updated" date at the end. We encourage you to review the policy whenever you
          use our Services to stay informed about our information practices and about the choices available to you.
        </p>
      </div>
      <div className={classes.div}>
        <h1>1. What This Privacy Notice Covers</h1>
        <p>
          The purpose of this Privacy Notice is to explain how ARQAAM secures, shares,
          and uses personal information that it collects and receives from you through
          its website {" "}
          <a href="www.arqaam-it.com">www.arqaam-it.com</a>, website hosting and design services,
          communication tools, mobile applications, app marketplace, ecommerce tools, and other services (collectively, the “Services”). If you have any questions or concerns
          regarding this Privacy Notice or our practices, please see the “Contact Us” section below.
        </p>
      </div>
      <div className={classes.div}>
        <h1>2. What Personal Information Does ARQAAM Collect?</h1>
        <span>
          ARQAAM may collect personal information as further described below
          through:
        </span>
        <p>
          • Transaction Information. When you use our Services, we collect information about when
          and where the transactions occurred, a description of the transactions, the amounts, and information about the payment methods used to complete the transactions. All credit/debit cards details and personally
          identifiable information will NOT be stored, sold, shared, rented, or leased to any third parties.
        </p>
        <p>
          •	Location Information. We may collect location information about your device if you have location services enabled.
        </p>
        <p>
          • Device Information. We collect device-specific information when you access our Services,
          including your device ID, hardware model, operating system and version,
          unique device identifiers, mobile network information,
          and information about the device's interaction with our Services.
          We may also identify other software running on the device (but will not collect any content from such software)
          for anti-fraud and malware-prevention purposes.
        </p>
        <p>
          •	Application Data. We may request access or permission to certain features from your mobile device including your mobile’s device storage.
          If you wish to change our access or permission, you may do so in your device’s settings
        </p>
        <p>
          •	User Information. We collect personal information, including,
          but not limited to your name, email address, Phone number, User ID,
          date of birth & gender. We collect information about how you use our Services,
          including your access times, browser types and language, and Internet Protocol ("IP")
          addresses.
        </p>
        <p>
          •	Push Notifications. We may request to send you push notifications
          like and not limited to messages, in-app messages, your account,
          or certain features of the application. If you wish to opt out from receiving
          these types of communications, you may turn them off in your device’s        </p>
        <p>
          •	Information Collected by Cookies and Web Beacons. We use various
          technologies to collect information, and this may include sending
          cookies to your computer or mobile device. Cookies are small data
          files that are stored on your hard drive or in device memory by a website.
          Among other things, cookies support the integrity of our registration process,
          retain your preferences, and account settings, and help evaluate and compile aggregated
          statistics about user activity. We may also collect information using web beacons.
          Web beacons are electronic images that may be used in our Services or emails.
          We may use web beacons to deliver cookies, count visits, understand usage and campaign effectiveness,
          and determine whether an email has been opened and acted upon.
        </p>

      </div>
      <div className={classes.div}>
        <h1>3. How Does ARQAAM Use Your Personal Information?</h1>
        <p>
          As further described below, ARQAAM may use the information it
          collects from you for any lawful purpose, including, without
          limitation, for providing products and services, identification
          and authentication, Service operation, analytics and improvements,
          support, contact (e.g., sending notifications related to use of the
          Service), marketing and promotions, research, and anonymous reporting
          to improve site functionality and the services we offer you,
          and for Service and both internal and external marketing analytics.
        </p>
        <p>
          We may use information we collect to send you news and information
          about our Services and to communicate with you about services, contests,
          promotions, incentives, and rewards offered by us and select partners.
        </p>
        <p>
          We may also use information about you to track and analyze trends and usage
          in connection with our Services; to process and deliver contest and promotion
          entries and rewards; to protect our rights or property; to verify your identity;
          to investigate and prevent fraud or other illegal activities; and for any other purpose
          disclosed to you in connection with our Services.
        </p>
      </div>
      <div className={classes.div}>
        <h1>4. Who does ARQAAM share your personal information with?</h1>
        <span>
          ARQAAM does not share personal information we learn about you from your use
          of the Service with others except as described in this Privacy Notice and in connection with:
        </span>
        <p>
          •	With third parties to provide, maintain, and improve our Services, including service
          providers who access information about you to perform services on our behalf (e.g., fraud
          prevention and verification services), and including financial institutions, processors, payment
          card associations, and other entities that are part of the payment or transfer process;
        </p>
        <p>
          •	With third parties that run advertising campaigns, contests, special offers, or other events or activities in connection with our Services;
        </p>
        <p>
          •	If we believe that disclosure is reasonably necessary to comply with
          any applicable law, regulation, legal process, or governmental request;
          to enforce applicable agreements or policies; to protect the security or
          integrity of our Services; and to protect us, our Charities, end users, or
          the public from harm or illegal activities; and with your consent;
        </p>
        <p>
          Services; and to protect us, our Charities, end users, or
          the public from harm or illegal activities; and with your consent;
        </p>
      </div>
      <div className={classes.div}>
        <h1>5. How long does ARQAAM retain your personal information?</h1>
        <p>
          As further described below, we will retain your information for as
          long as your account is active or as needed to provide you the Service,
          but in no event longer than permitted by applicable law.
        </p>
      </div>
      <div className={classes.div}>
        <h1>6.How Does ARQAAM Keep Your Personal Information Safe?</h1>
        <p>
          We take reasonable steps to put in place appropriate physical,
          electronic, and procedural safeguards to protect the integrity
          and security of personal information about you and to prevent unauthorized
          or unlawful processing of personal information and the accidental loss,
          destruction, or damage to personal information. These measures include internal
          reviews of our data collection, storage and processing practices and security measures
          (Strong password protection and in certain areas industry standard SSL-encryption to
          protect data transmissions), as well as physical security measures to guard against
          unauthorized access to systems where we store data.
        </p>
        <p>
          However, data transmissions over the Internet and methods of electronic
          storage are not 100% secure. Consequently, we cannot guarantee or warrant the
          security of any information you transmit to us, and you do so at your own risk.
        </p>
        <p>
          If ARQAAM learns of a security system breach we may attempt to notify
          you electronically so that you can take appropriate protective steps.
          We may post a notice on our applicable web sites if a security breach occurs.
          If this happens, you will need a web browser enabling you to view the applicable web sites.
          In these circumstances, we may also send an email to you at the email address you have provided to us.
          Depending on where you live, you may have a legal right to receive notice of a security breach in writing.
          To receive free written notice of a security breach (or to withdraw your consent from receiving electronic notice)
          submit a request through our help centre.
        </p>
      </div>
      <div className={classes.div}>
        <h1>7. What are your Privacy Rights?</h1>
        <p>
          ARQAAM as Data Controller. Upon request, we will provide you with
          information about whether we hold or process your personal information
          on behalf of third parties. You can access and edit your account
          information with ARQAAM at any time by logging into your account.
          You can also contact us via the help center, and we will respond
          as soon as possible, but at most within 30 days.
        </p>
        <p>
          You can delete your applicable account by visiting the
          applicable account deletion page; however, some personal
          information, primarily your contact information, may remain
          in our records to the extent necessary to protect our legal
          interests or document compliance with regulatory requirements,
          in each case solely to the extent permitted by applicable law.
        </p>
        <p>
          If your personal information changes, or if you no longer wish
          to use our Service, you may correct, update, or delete such
          information by making the change on your user account page,
          or by submitting a request through our help center.
        </p>
        <p>
          ARQAAM as Data Processor. ARQAAM acknowledges you have the
          right to access and change the personal information we collect
          and process as a data processor. An individual who seeks to access
          or to correct, amend, or delete personal information should direct
          their request to the ARQAAM user who is the data controller,
          and not to ARQAAM directly. If the data controller requests
          ARQAAM remove personal information we will respond within a
          reasonable timeframe.
        </p>
      </div>
      <div className={classes.div}>
        <h1>8. Third Party Features, Services, Links and Apps</h1>
        <p>
          Our Service includes various third-party features, services,
          and apps, including but not limited to social media features,
          such as the Facebook “Like” button, apps you may add to your
          Site via the App Marketplace, back end service providers,
          and Widgets, such as the Share This button or interactive
          mini-programs that run on our Service (“Third Party Features,
          Services, and Apps”). These Third-Party Features, Services,
          and Apps may collect your IP address, which page you are
          visiting on our site, and may set a cookie to enable the
          Third-Party Features, Services, and Apps to function properly.
          Third Party Features, Services, and Apps are either hosted by
          a third party or hosted directly on our Service.
          Your interactions with these Third-Party Features, Services,
          and Apps may be governed by the privacy policy of the company
          providing the Third Party Features, Services, and Apps,
          and you are responsible for viewing that company’s relevant
          privacy policy.
        </p>
      </div>
      <div className={classes.div}>
        <h1>9. Changes to this Privacy Notice</h1>
        <p>
          ARQAAM reserves the right to revise, modify, or update the
          website Policies and Terms & Conditions occasionally to meet
          the requirements and standards. Therefore, the Customers are
          encouraged to frequently visit these sections to be updated
          about the changes on the website. Modifications will be effective
          on the day they are posted”. We will notify you about material
          changes in the way we treat personal information by sending a
          notice to the primary email address specified in your
          particular’ ARQAAM account or by placing a prominent notice on
          our site.
        </p>
      </div>
      <div className={classes.div}>
        <h1>10. Contact Us</h1>
        <p>
          If you have a privacy concern regarding ARQAAM, or this notice,
          and if you cannot satisfactorily resolve it through the Service,
          you should complete a support request at {" "}
          <a href="https://arqaam-it.com">https://arqaam-it.com</a>, or you can
          write to us by email at {" "}<a href="mailto:support@arqaam-it.com">support@arqaam-it.com</a> or by mail at:
        </p>
        <p>
          Attention: Legal – Privacy
          Business Centre, Sharjah Publishing City Free Zone, Sharjah, United Arab Emirates

        </p>
        <p>
          If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact your local data protection authority
          to raise any concerns or complaints.
        </p>
        <span>Last Update: 06/02/2023</span>
      </div>
    </div>
  );
};

export default Privacy;
