import React, { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import classes from "./navbar.module.css";
import { Link } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import { useLocation } from "react-router-dom";
import iDoctorsLogo from "../../assets/icon/iDoctors logo with text-01.png";
import iDoctorsLogo2 from "../../assets/imgsDoctors/Asset 5.png";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

const languages = [
  {
    code: "en",
    name: "English",
    Country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    Country_code: "sa",
    dir: "rtl",
  },
];

const NavbarComponent = () => {
  const currentLangsCode = cookies.get("i18next") || "en";
  const currentLangs = languages.find((l) => l.code === currentLangsCode);
  const [t, i18n] = useTranslation();
  let location = useLocation();

  const [navbar, setNavbar] = useState(window.scrollY);
  const breakpoint = 66;

  useEffect(() => {
    window.addEventListener("scroll", () => setNavbar(window.scrollY));
  }, [navbar]);

  const scrollToNextSection = () => {
    scroller.scrollTo("Plans", { smooth: true, duration: 100 });
  };

  const scrollToNextSection1 = () => {
    // scroller.scrollTo("Features", { smooth: true, duration: 100 });
  };

  useEffect(() => {
    document.body.dir = currentLangs.dir || "ltr";
    document.title = t("app-title");
  }, [currentLangs, t]);

  return (
    <Element name="NavbarComponent">
      <div className={classes.header}>
        <Navbar
          className={`${classes.navbar} ${
            navbar > breakpoint && classes.navBackground
          }`}
          variant="dark"
          fixed="top"
          expand="lg"
          collapseOnSelect
        >
          <Navbar.Brand>
            <Link to="/">
              <img className={classes.logo} src={iDoctorsLogo2} alt="" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle className={classes.navbarToggle} />
          <Navbar.Collapse className={classes.navbarCollapse}>
            <Nav className={classes.nav}>
              <LinkContainer to="/">
                <Nav.Link onClick={scrollToNextSection1}>{t("home")}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/pricing">
                <Nav.Link >{t("pricing")}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/aboutus">
                <Nav.Link>{t("about-us")}</Nav.Link>
              </LinkContainer>
              {/* <LinkContainer to="/privacyPolicy">
                <Nav.Link onClick={scrollToNextSection}>
                  {t("privacyPolicy")}
                </Nav.Link>
              </LinkContainer> */}
           
              <LinkContainer to="/contactUS">
                <Nav.Link>{t("contactUs")}</Nav.Link>
              </LinkContainer>
            </Nav>
            {/* <LinkContainer to="/" className={classes.button1}>
                     <Nav.Link>Subscribe Now</Nav.Link>
                  </LinkContainer> */}
            {location.pathname !== "/plans" && (
              <a
                target={`_blank`}
                className={classes.button1}
                style={{ textDecoration: "none" }}
                href="https://play.google.com/store/apps/details?id=com.arqaam.i_doctors_app"
              >
                {t("Try it now")} !!
              </a>
            )}

            {i18next.language === "ar" && (
              <button
                className={classes.btnLang}
                onClick={() => {
                  i18next.changeLanguage("en");
                }}
              >
                <span
                  // className={`fi fi-gb`}
                  style={{
                    color: "#f1f1f1",
                    fontWeight: "bold",
                    marginRight: "5px",
                  }}
                >
                  EN
                </span>
              </button>
            )}

            {i18next.language === "en" && (
              <button
                className={classes.btnLang}
                onClick={() => {
                  i18next.changeLanguage("ar");
                }}
              >
                <span
                  // className={`fi fi-eg`}
                  style={{ color: "#f1f1f1", fontWeight: "bold" }}
                >
                  AR
                </span>
              </button>
            )}
          </Navbar.Collapse>
        </Navbar>
      </div>
    </Element>
  );
};

export default NavbarComponent;
