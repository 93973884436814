import classes from "./about.module.css";
import Footer from "../footer/Footer";
import { Col, Container, Row } from "react-bootstrap";
import aboutImg from '../../assets/imgsDoctors/about-us-asset.png';
import aboutImgEn from '../../assets/imgsDoctors/about-us-asset-ar.png';
import visionIcon from '../../assets/icon/vision.png';
import missionIcon from '../../assets/icon/target.png';
import coreIcon from '../../assets/icon/values.png';
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const languages = [
    {
        code: "en",
        name: "English",
        Country_code: "gb",
    },
    {
        code: "ar",
        name: "العربية",
        Country_code: "sa",
        dir: "rtl",
    },
];
const AboutUs = () => {
    const currentLangsCode = Cookies.get("i18next") || "en";
    const currentLangs = languages.find((l) => l.code === currentLangsCode);
    const [t, i18n] = useTranslation();
    useEffect(() => {
        document.body.dir = currentLangs.dir || "ltr";
        document.title = t("app-title");

    
    }, [currentLangs, t]);
    const lang = currentLangs.code == 'en'
    const style = {
        right: lang ? '220px' : 'auto',
        left: lang ? 'auto' : '230px', 
        width: lang? '138%':'145%'
  }
    function getImage() {
        if (!lang) {
          return aboutImg;
        } else if (lang) {
          return aboutImgEn;
        }
      }
    return (
        <>
            <div className={classes.subContainer}>
                <Container>
                    <Row className="flex-column-reverse flex-lg-row">
                 
                        <Col xl={6} xxl={6} lg={6} >
                            <div className={classes.about_content}>
                                <h1>{t("about-us")}</h1>
                                <p>{t("aboutFirstp")}.</p>
                                <p className={classes.about_content_items}> {t("aboutSecondP")}.
                                </p>
                                <p>{t("aboutThirdP")}.</p>
                                <Row>
                                    <Col lg={6}>
                                        <div className={classes.about_items}>
                                            <h4>{t("vision")}</h4>
                                            <img src={visionIcon} alt="vision_icon" width="30px" height="30px" />

                                        </div>
                                        {t("vision-text")}.
                                        </Col>
                                    <Col lg={6}>
                                        <div className={classes.about_items}>
                                            <h4>{t("mission")}</h4>
                                            <img src={missionIcon} alt="mission_icon" width="30px" height="30px" />
                                        </div>
                                        {t("mission-text")}.
                                    </Col>
                                </Row>
                                <div className={classes.about_items}>
                                    <h4> {t("core")} </h4>
                                    <img src={coreIcon} alt="core_icon" width="30px" height="30px" />
                                </div>
                                <ul className={classes.about_list}>
                                    <li><span>-</span> {t("Deal-with-Respect")}.</li>
                                    <li><span>-</span> {t("Create-Innovative-Solutions")}.</li>
                                    <li><span>-</span> {t("Develop-our-Personnel")}.</li>
                                    <li><span>-</span> {t("Digitize-Community")}.</li>
                                    <li><span>-</span> {t("Delight-Customer")}.</li>
                                </ul>

                            </div>
                        </Col>
                        <Col xl={6} xxl={6} lg={6} >
                            <div className={classes.aboutImg}>
                                <img src={getImage()} alt="about_img" style={style}  />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}
export default AboutUs;