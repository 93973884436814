import { Col, Container, Row } from "react-bootstrap"
import classes from "./pricing.module.css";
import Footer from "../footer/Footer";
import pricingImg from '../../assets/imgsDoctors/Asset.png'
import visa from '../../assets/imgsDoctors/visa.png';
import verfiedImg from  '../../assets/imgsDoctors/verfied.png';
import masterImg from  '../../assets/imgsDoctors/master.png';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import { useEffect } from "react";

const languages = [
  {
    code: "en",
    name: "English",
    Country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    Country_code: "sa",
    dir: "rtl",
  },
];
const Pricing = () => {
  const currentLangsCode = cookies.get("i18next") || "en";
  const currentLangs = languages.find((l) => l.code === currentLangsCode);
  const [t, i18n] = useTranslation();
  useEffect(() => {
    document.body.dir = currentLangs.dir || "ltr";
    document.title = t("app-title");
  }, [currentLangs, t]);
  const lang = currentLangs.code == 'en'
  const style = {
    right: lang ? '170px' : 'auto',
    left: lang ? 'auto' : '170px'
  }
  return (
    <>
      <div className={classes.subContainer}>
        <div className={classes.pricing_content}>
          <Row>
            <Col xl={7} xxl={7} lg={7} md={10} className={classes.pricing_items}>
              <h1>{t("pricing")}</h1>
              <p>{t("pricingFirstP")}.
              </p>
              <p>{t("pricingSecondP")}.</p>
              <p>{t("pricingThirdP")}.
              </p>
              <p> {t("pricingFourthP")} <br /> {t("pricingPayment")}.</p>
              <span className={classes.payment}>
                <img src={visa} alt="visa" />
              </span>
              <span className={classes.payment}>
                <img src={verfiedImg} alt="visa" />
              </span>
              <span className={classes.payment}>
                <img src={masterImg} alt="master" />
              </span>

            </Col>
            <Col xl={5} xxl={5} lg={5} className={classes.pricing_img} >
              <img src={pricingImg} style={style} width="100%" />
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default Pricing