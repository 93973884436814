import React from "react";
import Footer from "../components/footer/Footer";
import Business from "../components/home/Business";
import Features from "../components/home/Features";
import Header from "../components/home/Header";
import HowItWorks from "../components/home/HowItWorks";
import JoinDoctor from "../components/home/JoinDoctor";
import NavbarComponent from "../components/home/NavbarComponent";
import Plans from "../components/home/Plans";
import ReadyToUse from "../components/home/ReadyToUse";

const HomePage = () => {
  return (
    <div className="backgroundIMG">
      {/* <NavbarComponent /> */}
      <Header />
      <Features />
      <HowItWorks />
      <ReadyToUse />
      <JoinDoctor />
      <Footer />
      {/* <Business /> */}
    </div>
  );
};

export default HomePage;
