import React, { useEffect } from "react";
import classes from "./footer.module.css";
import fbIMG from "../../assets/imgsDoctors/fb.png";
import nounMail from "../../assets/imgsDoctors/noun-mail-1058930.png";
import linkedIMG from "../../assets/imgsDoctors/linked in.png";
import twitterIMG from "../../assets/imgsDoctors/twitter.png";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const languages = [
  {
    code: "en",
    name: "English",
    Country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    Country_code: "sa",
    dir: "rtl",
  },
];

const Footer = () => {
    const currentLangsCode = Cookies.get("i18next") || "en";
    const currentLangs = languages.find((l) => l.code === currentLangsCode);
    const [t, i18n] = useTranslation();
    useEffect(() => {
      document.body.dir = currentLangs.dir || "ltr";
      document.title = t("app-title");
    }, [currentLangs, t]);

  return (
    <div className={classes.footerContainer}>
    <div className={classes.footer__items}>
      <div className={classes.footer__link} >  
      <Link to="/privacyPolicy">
        {t("privacy-policy")}
      </Link>
      <Link to="/termsConditions">
       {t("terms&conditions")}
      </Link>
      </div>
      <div className={classes.copyRight}>
        <p>
          Copyright &copy;{" "}
          <a href="https://arqaam-it.com/#services" target="_blank">
            ARQAAM
          </a>{" "}
          2023
        </p>
        
      </div>
      <div className={classes.media}>
          <img src={fbIMG} alt="" />
          <img src={nounMail} alt="" />
          <img src={linkedIMG} alt="" />
          <img src={twitterIMG} alt="" />
        </div>
    </div>
    </div>
  );
};

export default Footer;
