import React from "react";
import HelpCenter from "../components/helpCenter/HelpCenter";

const HelpCenterPage = () => {
  return (
    <div className="backgroundIMG">
      <HelpCenter />;
    </div>
  );
};

export default HelpCenterPage;
