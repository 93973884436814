import React from "react";
import classes from "./contact.module.css";
import { Col, Container, Row } from "react-bootstrap";
import MessageUsIcon from "../../assets/imgsDoctors/Message us icon.png";
import contactUsIcon from "../../assets/imgsDoctors/contact us icon.png";
import Footer from "../footer/Footer";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const [t, i18n] = useTranslation();
  return (
    <>
      <div className={classes.subContainer}>
        <Container>
          <Row>
            <Col lg={6}>
              <div className={classes.contactLOGO}>
                <h1>{t("contactUs")}</h1>
                <img src={MessageUsIcon} alt="" />
              </div>
              <form className={classes.footerForm}>
                <h3>{t("Send us an email")}</h3>
                <input type="text" placeholder={t("name")} />
                <input type="email" placeholder={t('email')} />
                <input type="number" placeholder={t('number')} />
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder={t('message')}
                />
                <button className={classes.btn}>{t('send')}</button>
              </form>
            </Col>
            <Col lg={6} className="d-none d-lg-block">
              <div className={classes.contactUsIcon}>
                <img src={contactUsIcon} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
