import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./header.module.css";
import headerIMG from "../../assets/header.png";
import firstAssetHeader from "../../assets/imgsDoctors/first asset.png";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [t, i18n] = useTranslation();
  return (
    <header className={classes.header}>
      <Container fluid>
        <Row className={`${"flex-sm-column-reverse"} ${"flex-lg-row"}`}>
          <Col lg={5} className={`${classes.flexItem} col-md-12`}>
            <div className={classes.headerContent}>
              <p>
                {t('Online Medical')} <br />
                <span className={classes.minutes}>{t('headerOne')}</span>
              </p>
            </div>
          </Col>
          <Col lg={7} className="col-md-12">
            <div className={classes.headerImage}>
              <img src={firstAssetHeader} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
